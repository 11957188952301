// @ts-check
import {Button, TextField} from "@mui/material";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {cloneDeep} from "lodash";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {resetStatusPatchPrintDatesAction, savePrintDatesAction} from "../../redux/actions/print_actions";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {selectPrintDates, selectSavePrintDatesStatus} from "../../redux/print_selectors";
import {isRejected, isResolved} from "../../redux/utils/status";
import {sortDateTime} from "../../utils/luxon_helpers";
import Message from "../shared/message/message";
import CustomPickerDayPrint from "./components/custom_picker_day_print";
import DayNote from "./components/day_note";
import useStyles from "./enable_print_layer.styles";

/** @typedef {import("@mui/material").TextFieldProps} TextFieldProps */
/**
 * @typedef {Object} EventInputType
 * @property {HTMLInputElement} target
 */

/**
 * render EnablePrintLayer component
 * @return {React.ReactElement}
 */
export const EnablePrintLayer = () => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {now, format, plusDT, areSame, fromISO, startOf, endOf} = useContext(DateContext);

    const organizationId = useSelector(selectCurrentOrganizationId);
    const savePrintDatesStatus = useSelector(selectSavePrintDatesStatus);
    const printDates = useSelector(selectPrintDates);

    const [selectedDates, setSelectedDates] = useState([]); // array of DateTime object
    /** @type {[Object<string, string>, Function]} */
    const [notes, setNotes] = useState({}); // object of system date as key. ie {"2023-02-03": "some notes"}
    const [message, setMessage] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        // Reset the selected dates
        setSelectedDates([]);
        // once the printDates are updated, reset the selectedDates and init the notes
        const notes = {};
        printDates.forEach((allowedDate) => {
            const date = format(fromISO(allowedDate.date), DATE_FORMATS.SYSTEM_DATE);
            notes[date] = allowedDate.note;
        });
        setNotes(notes);
    }, [printDates]);

    useEffect(() => {
        if (isResolved(savePrintDatesStatus)) {
            setMessage(t("EnablePrintLayer.successSave"));
            // reset status
            dispatch(resetStatusPatchPrintDatesAction());
        }
        if (isRejected(savePrintDatesStatus)) {
            setError(t("EnablePrintLayer.successSave"));
        }
    }, [savePrintDatesStatus]);
    const handleSave = () => {
        dispatch(
            savePrintDatesAction(
                organizationId,
                Object.entries(notes).map(([date, note]) => ({date, note}))
            )
        );
    };
    /**
     * handle click on the day in the calendar
     *
     * @param {DateTimeType} date
     * @return {void}
     */
    const handleClickDate = (date) => {
        // reset message and error
        setMessage(null);
        setError(null);
        const isToday = areSame(now(), date, "day");

        // If today or one of the printDates was clicked, do nothing since it can not be reverted
        if (isToday || printDates.find((allowedDate) => areSame(fromISO(allowedDate.date), date, "day"))) {
            return undefined;
        }

        // If the date is already selected, remove the date from the selectedDate and clear the note
        // else, add the date to selectedDates, note with empty string in the notes
        if (selectedDates.find((selectedDate) => areSame(selectedDate, date, "day"))) {
            setSelectedDates(selectedDates.filter((selectedDate) => !areSame(selectedDate, date, "day")));

            setNotes((prevNotes) => {
                const newNotes = {...prevNotes};
                delete newNotes[format(date, DATE_FORMATS.SYSTEM_DATE)];
                return newNotes;
            });
        } else {
            const newSelectedDates = cloneDeep(selectedDates);
            newSelectedDates.push(date);
            setSelectedDates(newSelectedDates);

            notes[format(date, DATE_FORMATS.SYSTEM_DATE)] = "";
            setNotes((prevNotes) => {
                const newNotes = {...prevNotes};
                newNotes[format(date, DATE_FORMATS.SYSTEM_DATE)] = "";
                return newNotes;
            });
        }
        return undefined;
    };

    /**
     * handler for the notes
     *
     * @param {DateTimeType} date
     * @return {ChangeEventHandler}
     */
    const handleNotes = (date) => (e) => {
        /** @link https://github.com/testing-library/user-event/issues/533 */
        const value = e.target.value;
        setNotes((notes) => ({...notes, [format(date, DATE_FORMATS.SYSTEM_DATE)]: value}));
    };

    // /** @type {DateTimeType[]} */
    const allPrintableDates = useMemo(() => {
        // merge dates from the printDates and selectedDates, remove duplicates by date
        const mergedDates = [...printDates.map((allowedDate) => fromISO(allowedDate.date)), ...selectedDates].reduce(
            (unique, item) => (unique.find((date) => areSame(date, item, "day")) ? unique : [...unique, item]),
            []
        );

        // if today is not included (today is not allowed yet), add today in the mergedDates
        if (!mergedDates.find((date) => areSame(date, now(), "day"))) {
            mergedDates.push(startOf(now(), "day"));
        }
        return mergedDates.sort(sortDateTime).filter((date) => date >= startOf(now(), "day"));
    }, [printDates, selectedDates]);

    return (
        <div className={cx(classes.root, classes.table)}>
            {message && <Message message={message} />}
            {error && <Message message={error} severity="error" />}
            <div className={classes.title}>{t("EnablePrintLayer.title")}</div>
            <div className={classes.content}>
                <span>{t("EnablePrintLayer.text")}</span>
                <StaticDatePicker
                    disableHighlightToday={true}
                    displayStaticWrapperAs="desktop"
                    maxDate={plusDT(now(), "month", 3)}
                    minDate={startOf(now(), "day")}
                    renderDay={(day, selectedDays, pickersDayProps) => {
                        const isDateAllowed = printDates
                            ?.filter((allowedDate) => fromISO(allowedDate.date) > endOf(now(), "day"))
                            .find((allowedDate) => areSame(fromISO(allowedDate.date), day, "day"));
                        const isDateSelected = selectedDates.find((selectedDate) => areSame(selectedDate, day, "day"));
                        return (
                            <CustomPickerDayPrint
                                date={day}
                                key={format(day, DATE_FORMATS.DATE)}
                                pickersDayProps={pickersDayProps}
                                selected={Boolean(isDateAllowed || isDateSelected)}
                            />
                        );
                    }}
                    renderInput={(params /** @param {TextFieldProps} params */) => (
                        // @ts-ignore couldn't solve error if TextField props
                        <TextField variant="standard" {...params} className={classes.staticDatePicker} />
                    )}
                    value={selectedDates.concat(printDates?.map((date) => fromISO(date.date)))}
                    onChange={handleClickDate}
                />
                <div className={classes.border} />
                <div className={classes.textWrapper}>
                    <div className={classes.textLabel}>{t("EnablePrintLayer.addNotes")}</div>
                    {allPrintableDates.map((date) => (
                        <DayNote
                            dateLabel={format(fromISO(date), DATE_FORMATS.DATE_SHORT_ABBREVIATED)}
                            key={date.toISO()}
                            note={notes[format(date, DATE_FORMATS.SYSTEM_DATE)] || ""}
                            onChange={handleNotes(date)}
                        />
                    ))}
                </div>
            </div>

            <div className={classes.footer}>
                <Button
                    color="primary"
                    data-testid="printButton"
                    disabled={allPrintableDates.length === 0}
                    variant="contained"
                    onClick={handleSave}
                >
                    {t("EnablePrintLayer.save")}
                </Button>
            </div>
        </div>
    );
};

export default EnablePrintLayer;
