// @ts-check
import _ from "lodash";

import {getDateTimeFromISOWithTimezone} from "../../../../utils/luxon_helpers";

const SLOT_HEIGHT = 24;
const PADDING = 12;
const MIN_HEIGHT = 40;

/**
 * Gets the list of slots per practitioner and calculates the maximum amount of slots
 * in one day.
 *
 * @param {Array<DataSlotAbsences>|Array<DataSlotAvailabilities>} dataSlotList The list of slots for a practitioner
 * @param {String} timezone The timezone
 * @return {number} The amount of slots in the day with the most amount
 */
const getMaxNumOfSlotsInOneDay = (dataSlotList, timezone) => {
    const dicOfDays = {};
    let maxNumOfSlots = 1;

    dataSlotList.forEach((date) => {
        const dayOfWeek = getDateTimeFromISOWithTimezone(date.start, timezone).day;

        if (dicOfDays[dayOfWeek] !== undefined) {
            dicOfDays[dayOfWeek] = dicOfDays[dayOfWeek] + 1;

            if (maxNumOfSlots <= dicOfDays[dayOfWeek]) maxNumOfSlots = dicOfDays[dayOfWeek];
        } else {
            dicOfDays[dayOfWeek] = 1;
        }
    });
    return maxNumOfSlots;
};

/**
 * Get the size of each row on the Availabilities table depending on the number of slots per day
 *
 * @param {object} params
 * @param {number} params.index
 * @param {Array<EmployeeData>} params.dataOrganized
 * @param {string} params.timezone
 * @param {boolean} params.hasPractitionerId
 * @return {number} The row height
 */
export const getItemsSize = ({index, dataOrganized, timezone, hasPractitionerId}) => {
    const currentPractitioner = dataOrganized[index];
    const {availabilities} = currentPractitioner;
    const {absences} = currentPractitioner;
    const absencesNoDuplicates = _.uniqBy(absences, "slotId");
    const hasAvailabilities = availabilities.length > 0;
    const hasAbsences = absences.length > 0;

    const maxNumOfAvailabilities = hasAvailabilities ? getMaxNumOfSlotsInOneDay(availabilities, timezone) : 0;
    const maxNumOfAbsences = hasAbsences ? getMaxNumOfSlotsInOneDay(absencesNoDuplicates, timezone) : 0;

    const numOfSlots = maxNumOfAvailabilities + maxNumOfAbsences;

    /** @description The first row needs extra height because of the extra border bottom */
    if (hasPractitionerId && index === 0) return numOfSlots ? PADDING + SLOT_HEIGHT * numOfSlots + 5 : MIN_HEIGHT;

    /** @description If the numbers of slots is ONE the row needs extra height to make the bottom border visible*/
    if (numOfSlots === 1) return (PADDING + SLOT_HEIGHT + 6) * numOfSlots;

    /** @description If the number of slots is 0, set minimum height, else set the height accoding to the number of slots */
    return numOfSlots ? PADDING + SLOT_HEIGHT * numOfSlots : MIN_HEIGHT;
};

export const sortPeriods = (a, b) => {
    if (a.start < b.start) {
        return -1;
    }

    if (a.start > b.start) {
        return 1;
    }

    if (a.end < b.end) {
        return -1;
    }

    if (a.end > b.end) {
        return 1;
    }

    return 0;
};
