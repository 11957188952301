/**
 * @fileOverview logo
 */

import PropTypes from "prop-types";
import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {selectLogo} from "../fe_settings/fe_settings_selectors";
import useStyles from "./logo.style";

const Logo = ({className}) => {
    const {classes, cx} = useStyles();

    const logoPath = useSelector(selectLogo);

    const src = logoPath || "/images/10000.png";

    return (
        <div className={cx(className)}>
            <Link to="/">
                <img alt="Logo" className={classes.image} src={src} />
            </Link>
        </div>
    );
};

Logo.propTypes = {
    className: PropTypes.string
};

export default Logo;
